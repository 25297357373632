import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import React from 'react';

export const Badges = (props) => {
  const [image2, setImage2] = React.useState('');
  const [title2, setTitle2] = React.useState('');
  const [text2, setText2] = React.useState('');

  React.useEffect(() => {
    console.log(image2, title2, text2);
  }
    , [image2, title2, text2]);

  return (
    <div className='text-center'>
      <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content text-center">
            <div className="modal-header" data-dismiss="modal">
              <img src={image2} alt='chipLarge' className='chipLarge animate__animated animate__flipInY' data-dismiss="modal" />
              <h2 className="modal-title mt-3" id="exampleModalLabel">{title2}</h2>
              <h3>{text2}</h3>
            </div>
          </div>
        </div>
      </div>
      <div id='badges' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <div className='row explain'>
              <div className='col-xs-8 col-xs-offset-2 col-md-offset-0 col-md-12 text-center container'>
                <Fade bottom>
                  <img src='./img/Chip-locked-images/LOGOcircle2.png' alt='logoCircle' className='logoCircle' /><h2> What are Chip-locked season badges?</h2>
                </Fade>
                <hr />
                <Fade bottom>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    <p>Achievements happen during your season, some good and some bad, with Chip-locked Season Badges you can show everyone how your year has
                      been going. By attaching each velcro-backed badge to your bag as you earn them while playing, everyone will see the highlights and lowlights
                      of the season you've been having. So pick up a pack of badges and show off your skills and tough breaks!
                    </p>
                  </div>
                  <div className='mt-5'
                    dangerouslySetInnerHTML={{
                      __html: `<video class="video2" autoplay loop muted playsinline>
      <source src='./img/badges/SeasonBadgesBeginnerPackAD.mp4' type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                    }}
                  />
                </Fade>
              </div>
            </div>
            <div id="chiplogo" />
            <div className='border p-3'>
              <div className='row'>
                <div className='col-xs-12 col-md-10 col-md-offset-1 '>
                  <Fade>
                    <a href="https://www.amazon.com/dp/B0BZLVX5ZQ" target="_blank" rel="noopener noreferrer">
                      <img src='./img/badges/SeasonBadgeBeginner.png' alt='logo' className='logoLarge2' />
                    </a>
                  </Fade>
                </div>
              </div>
              <div data-dismiss className='row'>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/chiplockedpatch.png'); setTitle2('CHIP-LOCKED CHAMP BADGE'); setText2('Earned by winning a round of Chip-locked in a group of 4 or more players')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/chiplockedpatch.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/bountybirdiepatch.png'); setTitle2('BOUNTY BIRDIE BADGE'); setText2("Earned by getting a birdie on a hole that is rarely birdied")
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/bountybirdiepatch.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/lostdiscpatch.png'); setTitle2('LOST DISC BADGE'); setText2('Earned by losing a disc anywhere but in water')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/lostdiscpatch.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/turkeypatch.png'); setTitle2('TURKEY BADGE'); setText2('Earned by getting 3 birdies in a row during a round')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/turkeypatch.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/new.png'); setTitle2('FIRST AVAILABLE BADGE'); setText2('Earned by hitting something less than 30ft off the tee')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/new.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/parmageddonpatch.png'); setTitle2('PAR-MAGEDDON BADGE'); setText2('Earned by having a bogey free round')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/parmageddonpatch.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
              </div>

            </div>

            <div className='border p-3'>
              <div className='row'>
                <div className='col-xs-12 col-md-10 col-md-offset-1 '>
                  {/* HEre are the advanced badges: */}
                  <Fade>
                    <a href="https://www.amazon.com/dp/B0CRBLVWQR" target="_blank" rel="noopener noreferrer">
                      <img src='./img/badges/SeasonBadgeAdvanced.png' alt='logo' className='logoLarge2' />
                    </a>
                  </Fade>
                </div>
              </div>
              <div data-dismiss className='row'>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003331.png'); setTitle2('ACE BADGE'); setText2('Earned by getting a hole in one ')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003331.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003328.png'); setTitle2('FULL FLIGHT BADGE'); setText2("Earned by getting a birdie on every hole at one course during a season")
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003328.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003333.png'); setTitle2('BOMBER BADGE'); setText2('Earned by throwing a drive into circle one on a 400+ft hole par 3')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003333.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003332.png'); setTitle2('BUSHWHACKER BADGE'); setText2('Earned by getting birdie or better on a par 4 or 5 without ever being on the fairway or in circle 2')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003332.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>

                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003329.png'); setTitle2('ROUND KILLER BADGE'); setText2('Earned by getting a score a +5 or more on a hole')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003329.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
                <Fade bottom>
                  <div className='col-xs-6 col-sm-6 col-md-4'>
                    <img onClick={() => {
                      setImage2('./img/badges/1000003330.png'); setTitle2('SEEING CIRCLES BADGE'); setText2('Earned by making 4 or more circle 2 putts in one round')
                    }}
                      data-toggle="modal" data-target="#exampleModal3"
                      src='./img/badges/1000003330.png' alt='chiplockedSeasonBadges' className='badges' />
                  </div>
                </Fade>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



