import Fade from 'react-reveal/Fade';
export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Fade bottom>
              <img src="img/Chip-locked-images/STICKERlogoV2.png" className="img-responsive" alt="" />
            </Fade>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <Fade right>
                <h2>About ChipLocked</h2>
              </Fade>
              <p>Hi! I'm the creator of Chip-locked, Jase. I've been playing disc golf for many years now and
                it's one of my favorite ways to spend an afternoon with friends and family. Since I first started
                playing, my brother and I have always enjoyed competing but lately our skill levels have started
                to get further and further apart and the rounds began losing that competitive spirit. That's why
                I made Chip-locked, to bring back that competitive spirit! We found as we tested that we were having
                more fun right away, each shot had more excitement, there always was a chance for the lead to change 
                on each hole. We also liked that playing didn't get in the way of a normal round. Chip-locked is now 
                a part of every round. The exchanging of chips between the holes was quick and satisfying when you take 
                a good chip from your opponent or give your bad chip to them! If you're looking to throw some more fun 
                into your round or just compete between players of varying experience levels then Chip-locked is for you!</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
