import { Zoom, Fade, Flip } from "react-reveal"
export const Play = (props) => {
  return (
    <div id='play' className='text-center'>
      <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content text-center">
            <div className="modal-header" data-dismiss="modal">
              <h2>Chip-locked</h2>
              <div right>
                <img src='./img/Chip-locked-images/logoBlue.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>CHIP SCORE</h4>
                <p className='text-center'>at the end of the round players check to see who has the lowest score by
                  adding their bad chips and subtracting their good chips</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoGreen.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>CHIPS AND STROKES</h4>

                <p className='text-center'>players subtract strokes for their good chips and add strokes for
                  their bad chips to their total strokes at the end of the round | remove the STROKE chip from
                  play</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoPurple.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>FRONT AND BACK 9 CHIP SCORE</h4>
                <p className='text-center'>players combine two chip scores, after the front and
                  back 9, resetting the chips after the front 9</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoGray.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>CHIP BETTING</h4>
                <p className='text-center'>(one set of Chip-locked needed per player) players select one good chip
                  and one bad chip that will be in play at the beginning of each hole, players reveal what chips
                  they chose after all players have thrown their drive, if multiples of one chip are chosen then
                  that chip will be worth points equal to the number in play, the chip score is added at the end of
                  each hole and the chips are reset</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoOrange.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>ACCUMULATION CHIP SCORE</h4>
                <p className='text-center'>the chip score is added or subtracted after each hole
                  without resetting the chips</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoYellow.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>GO FOR IT CHIP SCORE</h4>
                <p className='text-center'>only good chips are in play, pick up any discs not in the basket
                  after a player's throw for pa</p>
              </Zoom>
              <hr />

              <div right>
                <img src='./img/Chip-locked-images/logoPink.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>ONE-SIDED CHIPS AND STROKES</h4>
                <p className='text-center'>players can only earn one type of chip depending on
                  their skill level, bad chips are in play for experienced players and good chips are in play for
                  inexperienced players, subtract strokes for good chips and add strokes for bad chips to each
                  players total strokes at the end of the round | remove the STROKE chip from play</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoLightBlue.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>ABSOLUTE CHIPS AND STROKES</h4>
                <p className='text-center'>players subtract strokes for both good and bad chips to
                  their total strokes at the end of the round | remove the STROKE chip from play</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoRed.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>SOLO</h4>
                <p className='text-center'>try to beat your personal best chip score</p>
              </Zoom>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content text-center">
            <div className="modal-header" data-dismiss="modal">
              <h2>Chip-locked Badges</h2>
              <div right>
                <img src='./img/Chip-locked-images/logoBlue.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>Solo Season</h4>
                <p className='text-center'>It's time to clean house! Remove all of your Chip-locked Season Badges from last year and 
                start fresh. Add each badge as you earn it and try to complete the whole pack before the season ends. Let's hope your 
                bag doesn't get too heavy!</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoGreen.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>Friends Season</h4>

                <p className='text-center'>Let the games begin! Start by removing all of yours and your friend's Chip-locked Season 
                Badges at the beginning of the season. As you play, add badges to your bag if you don't currently have them. If a friend 
                you're playing with has the badge you just earned, they have to surrender it. It's like a game of keep away but with badges. 
                To win the Friends Season, you'll need to have the most good badges (minus your bad ones, of course)</p>
              </Zoom>
              <hr />
              <div right>
                <img src='./img/Chip-locked-images/logoPurple.png' className='smallLogo' alt="" />
              </div>
              <Zoom>
                <h4>Sprint Season</h4>
                <p className='text-center'>Ready. Set. Go! Be the first of your friends to earn all the good badges and you win, but if you 
                earn a bad badge then you have to remove any good badges currently on your bag and start over. Don't be too bummed though because 
                once you earned a bad badge you don't have to worry about it anymore!</p>
              </Zoom>
              <hr />
              
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='row section-title'>
          <div className='col-xs-12 col-md-12 col-sm-12'>
            <div className="">
              <Fade up>
                {/* <img src='./img/Chip-locked-Rules.png' className='play transition' alt="" /> */}
                <div className=" ">
                  <Fade>
                    <h2 className="">Ways to Play Chip-locked</h2>
                  </Fade>
                  <hr className="waysTexthr" />
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <Fade right>
                      <img src='./img/Chip-locked-images/logoGreen.png' className='mediumLogo' alt="" />
                      <br />
                        <button className="ways" data-toggle="modal" data-target="#exampleModal2">
                          Chip-locked Game
                        </button>
                      </Fade>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <Fade left>
                      <img src='./img/Chip-locked-images/logoPurple.png' className='mediumLogo' alt="" />
                      <br />
                        <button className="ways" data-toggle="modal" data-target="#exampleModal4">
                        Chip-locked Badges
                        </button>
                      </Fade>
                    </div>
                  </div>

                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
