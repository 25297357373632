import { Fade, Zoom } from "react-reveal"
// import FontAwesomeIcon from "@fortawesome/react-fontawesome"
export const Rules = (props) => {
  return (
    <div id='rules'>
      <div className='container'>
        <div className='section-title'>
          <div className='row'>
            <div className='col-xs-12 text-center'>
              <Fade bottom>
                <img src='./img/Chip-locked-images/LOGOcircle2.png' alt='logoCircle' className='logoCircle' />
                <h2> How to Play</h2>
              </Fade>
              <hr />

              <a href='./img/Chip-locked-Rules.png' target='_blank'>
                <Zoom>
                  <img src='./img/Chip-locked-Rules.png' className='play transition' alt="" />
                </Zoom>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}