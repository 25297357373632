export const Header = (props) => {

  function changeBgImage() {
    setTimeout(() => {
      document.querySelector('.intro').style.filter = 'grayscale(0%)';
      document.querySelector('.intro').style.ease = 'ease-in-out';

    }
      , 3000);

  }

  changeBgImage();


  return (
    <header id='header'>
      <img width="200" src="img/disc2.png" className="disc" alt="discimage" />
      <a href="https://chiplocked.square.site/"
        target="_blank" rel="noopener noreferrer">
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>

              <h2 className="text-center buyNowText">SHOP ALL</h2>


            </div>
          </div>
        </div>
      </a>
      <div className='row buyNowDiv text-center'>
        <div className=' col-xs-4 text-center'>
          <a href="https://chiplocked.square.site/product/chip-locked-season-badges-beginner-pack/5?cs=true&cst=custom" target="_blank" rel="noopener noreferrer">
            <img src="./img/beginnerButton.png" alt="amazon-button" className="buyNow text-center" />
          </a>
        </div>
        <div className='col-xs-4 text-center'>
          <a href="https://chiplocked.square.site/product/chip-locked-disc-golf-game/3?cs=true&cst=custom" target="_blank" rel="noopener noreferrer">
            <img src="./img/gameButton.png" alt="amazon-button" className="buyNow drop text-center" />
          </a>
        </div>
        <div className='col-xs-4 text-center'>
          <a href="https://chiplocked.square.site/product/chip-locked-season-badges-advanced-pack/6?cs=true&cst=custom" target="_blank" rel="noopener noreferrer">
            <img src="./img/advancedButton.png" alt="amazon-button" className="buyNow text-center" />
          </a>
        </div>
      </div>
    </header>
  )
}
