import Swing from 'react-reveal/Swing';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import React from 'react';
export const Shop = (props) => {
  return (
    <div id='shop' className='text-center'>
      <div className='container border'>
        <div className='container'>
          {/* <iframe src="https://chiplocked.square.site/" width="100%" height="100%"></iframe> */}
          <Fade bottom>
            <img src='./img/Chip-locked-images/LOGOcircle2.png' alt='logoCircle' className='logoCircle' />
            <a href="https://chiplocked.square.site/" target="_blank" rel="noopener noreferrer"><h2>Shop</h2></a>
            <hr className='mb-3' />
          </Fade>
          <div className='row'>
            {/* button buy now to square link  */}

            <a href="https://chiplocked.square.site/" target="_blank" rel="noopener noreferrer">
              <div className='col-sm-10 col-sm-offset-1 mb-3'>
                <div id="my-pics" className="carousel slide" data-ride="carousel" >
                  <ol className="carousel-indicators">
                    <li data-target="#my-pics" data-slide-to="0" className="active"></li>
                    <li data-target="#my-pics" data-slide-to="1"></li>
                    <li data-target="#my-pics" data-slide-to="2"></li>
                    <li data-target="#my-pics" data-slide-to="3"></li>
                    <li data-target="#my-pics" data-slide-to="4"></li>
                    <li data-target="#my-pics" data-slide-to="5"></li>
                    <li data-target="#my-pics" data-slide-to="6"></li>
                    <li data-target="#my-pics" data-slide-to="7"></li>
                    <li data-target="#my-pics" data-slide-to="8"></li>
                    <li data-target="#my-pics" data-slide-to="9"></li>
                    {/* <li data-target="#my-pics" data-slide-to="7"></li> */}
                  </ol>
                  <div className="carousel-inner" role="listbox">

                    {/* <!-- Slide 1 --> */}
                    <div className="item active">
                      <img src="./img/setblurredbkgd.jpg" alt="Chip Locked" />
                    </div>

                    {/* <!-- Slide 2 --> */}
                    <div className="item">
                      <img src="./img/bagsinbasket.jpg" alt="chip locked images" />
                    </div>

                    {/* <!-- Slide 3 --> */}
                    <div className="item">
                      <img src="./img/bagslaketable.jpg" alt="bagslaketable" />
                    </div>
                    <div className="item">
                      <img src="./img/MAIN.jpg" alt="chiplocked" />
                    </div>
                    <div className="item">
                      <img src="./img/chipsMany.jpg" alt="many chips" />
                    </div>
                    <div className="item">
                      <img src="./img/badges/bagCloseup.jpg" alt="bagCloseup" />
                    </div>
                    <div className="item">
                      <img src="./img/badges/badgePackaging.jpg" alt="badgePackaging" />
                    </div>
                    <div className="item">
                      <img src="./img/badges/badgeCollection.jpg" alt="badgePackaging" />
                    </div>
                    <div className="item">
                      <img src="./img/badges/1000003767.jpg" alt="badgePackaging" />
                    </div>
                    <div className="item">
                      <img src="./img/badges/1000003653.jpg" alt="badgePackaging" />
                    </div>
                  </div>

                  {/* <!-- Previous/Next controls --> */}
                  <a className="left carousel-control" href="#my-pics" role="button" data-slide="prev">
                    <span className="icon-prev" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="right carousel-control" href="#my-pics" role="button" data-slide="next">
                    <span className="icon-next" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>

                </div>
              </div>
            </a>
          </div>
          <div className='text-center'>
            <a href="https://chiplocked.square.site/" target="_blank" rel="noopener noreferrer">
              <div className='text-center'>
                <img src="./img/shopButtonFreee.jpg" alt="logo" className="text-center logoMd" />

              </div>
            </a>
          </div>

        </div>
        {/* <hr className='light' />
        <h3 className='text-center mb-3'>Chip Locked is also available at these fine retailers:</h3>
        <div className='col-sm-6 col-md-3'>
          <a className="" href="https://www.amazon.com/dp/B09RQNFPVN" target="_blank" rel="noopener noreferrer">
            <img src='./img/amazon.jpg' alt='amazonGreen' className='amazonGreen' />
          </a>
        </div>
        <div className='col-sm-6 col-md-3'>
          <a className="" href="https://www.ssdiscgolf.com/product-page/chip-locked-disc-golf-game" target="_blank" rel="noopener noreferrer">
            <img src='./img/slipperySaucers.jpg' alt='slippery' className='amazonGreen' />
          </a>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-3'>
            <a className="amazon" href="https://highlinediscgolf.myshopify.com/products/chiplocked-disc-golf-game" target="_blank" rel="noopener noreferrer">
              <img src='./img/highLine.jpg' alt='highline' className='amazonGreen' />
            </a>
          </div>
          <div className='col-sm-12 col-md-3'>
            <a className="amazon" href="https://foundationdiscs.com/collections/disc-golf-accessories/products/chiplocked" target="_blank" rel="noopener noreferrer">
              <img src='./img/foundation.png' alt='foundation' className='amazonGreen' />
            </a>
          </div> */}
        {/* </div> */}
        <hr className='light' />
        <div className='container'>
          <div className="">
            <div className="row">
              <div className='col-md-10 col-md-offset-1'>
                <Fade left>
                  <img src='./img/Chip-locked-images/merch.png' alt='merch' className='merch' />
                </Fade>
              </div>
            </div>
          </div>
          <div className="">
            <div className='row'>
              {props.data
                ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-xs-6 col-md-4 col-lg-3'>
                    <a href={`${d.link}`}>
                      <Swing>
                        <img src={`${d.url}`} alt='shirt' className='shirt shake' />
                      </Swing>
                    </a>
                    <h3>{d.description}</h3>
                    <h3 className="style">Available in 4 styles</h3>
                    <p>{d.text}</p>
                  </div>
                ))
                : 'Loading...'}
            </div>
            <div className='row mt-5 mb-5'>
              <div className='col-xs-12'>
                <Fade right>
                  <a className="bonfireText transition" href="https://www.bonfire.com/store/chip-locked/">
                    View more styles here
                  </a>
                </Fade>
              </div>
            </div>
            <hr className='light' />
            <h3 className='text-center mb-3'>Chip Locked is also available at these fine retailers:</h3>
            {/* <div className='col-sm-6 col-md-3'>
              <a className="" href="https://www.amazon.com/dp/B09RQNFPVN" target="_blank" rel="noopener noreferrer">
                <img src='./img/amazon.jpg' alt='amazonGreen' className='amazonGreen' />
              </a>
            </div> */}
            <div className='col-xs-4'>
              <a className="" href="https://www.ssdiscgolf.com/product-page/chip-locked-disc-golf-game" target="_blank" rel="noopener noreferrer">
                <img src='./img/slipperySaucers.jpg' alt='slippery' className='amazonGreen' />
              </a>
            </div>
              <div className='col-xs-4'>
                <a className="" href="https://highlinediscgolf.myshopify.com/products/chiplocked-disc-golf-game" target="_blank" rel="noopener noreferrer">
                  <img src='./img/highLine.jpg' alt='highline' className='amazonGreen' />
                </a>
              </div>
              <div className='col-xs-4'>
                <a className="" href="https://foundationdiscs.com/collections/disc-golf-accessories/products/chiplocked" target="_blank" rel="noopener noreferrer">
                  <img src='./img/foundation.png' alt='foundation' className='amazonGreen' />
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
